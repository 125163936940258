<template>
  <div class="index-register">
    <div class="row">
      <div class="col-1 register-left"></div>
      <div class="col-11">
        <div class="register-container">
 <form @submit.prevent="submitHandler" class="pt-5 pb-5">
              <div class="register shadow-sm">
                <div class="mb-3">
                  <img src="../../assets/logo.png" alt width="130" />
                  <div class="login-icon">
                    <h3>
                      <em>Register</em>
                    </h3>
                  </div>
                  <label for="name">Username</label>
                  <br />
                  <input
                    type="text"
                    placeholder="username"
                    v-model.trim="user.username"
                    @blur="$v.user.username.$touch"
                  />
                  <!-- <input type="text" placeholder="username" v-model="$v.username.$model" /> -->
                  <template v-if="$v.user.username.$error">
                    <div
                      v-if="!$v.user.username.required"
                      class="error-message"
                    >Username is required</div>
                    <div
                      v-if="!$v.user.username.minLength"
                      class="error-message"
                    >Username should be more than 3 simbols!</div>
                  </template>
                  <div class="mb-3">
                    <label for="name">Email</label>
                    <br />
                    <input
                      type="email"
                      placeholder="email"
                      v-model.trim="user.email"
                      @blur="$v.user.email.$touch"
                    />
                    <template v-if="$v.user.email.$error">
                      <div v-if="!$v.user.email.required" class="error-message">Email is required</div>
                      <div v-if="!$v.user.email.minLength" class="error-message">Invalid email!</div>
                    </template>
                  </div>
                  <div class="mb-3">
                    <label for="name">Password</label>
                    <br />

                    <input
                      type="password"
                      placeholder="password"
                      v-model.trim="user.password"
                      @blur="$v.user.password.$touch"
                    />
                    <template v-if="$v.user.password.$error">
                      <div
                        v-if="!$v.user.password.required"
                        class="error-message"
                      >password is required</div>
                      <div
                        v-if="!$v.user.password.minLength"
                        class="error-message"
                      >password should be mor than 8 simbols!</div>
                    </template>
                  </div>
                  <div class="mb-3">
                    <label for="name">Confirm</label>
                    <br />

                    <input
                      type="password"
                      placeholder="confirm password"
                      v-model.trim="user.confirmPassword"
                      @blur="$v.user.confirmPassword.$touch"
                    />
                    <template v-if="$v.user.confirmPassword.$error">
                      <div
                        v-if="!$v.user.confirmPassword.sameAs"
                        class="error-message"
                      >Password don't match!</div>
                    </template>
                  </div>
                </div>
                <template v-if="message">
                  <p class="error-message">{{message.message}}</p>
                </template>
                <button
                  class="btn btn-success"
                  :disabled="$v.$invalid"
                  value="Register"
                  type="submit"
                >Register</button>
                <div class="pt-2">
                  <router-link to="/login">Login</router-link>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";

import User from "../../models/user";

export default {
  mixins: [validationMixin],
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      message: "",
      key: "",
      ifKey: "",
    };
  },
  validations: {
    user: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirmPassword: {
        sameAs: sameAs("password"),
      },
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    submitHandler() {
      this.message = "";
      this.submitted = true;
      this.$store.dispatch("auth/register", this.user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.$router.push("/login");
        },
        (error) => {
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
        }
      );
    },
    async messageTimeOut() {
      await setTimeout(() => {
        this.message = "";
      }, 3000);
    },
  }
};
</script>

<style scouped>
.index-register {
  padding: 1rem;
}
.empty {
  padding-bottom: 10%;
}

.register-container {
  text-align: center;
  width: 50%;
  margin-left: auto;
  padding: 5rem 10rem 10rem 10rem;
}
.register {
  background-color: #f8f9fa;
  /* max-width: 50%; */
  margin: 0 auto;
  border-radius: 8%;
  padding: 3rem 0;
}
.error-message {
  color: red;
}
@media (max-width: 576px) {
  .register-container {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    /* margin-left: auto; */
    padding: 5rem 0rem 10rem 0rem;
  }
  .register {
    background-color: transparent;
  }
  .login {
    background-color: transparent;
  }
}
@media (max-width: 1024px) {
  .register-container {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    padding: 5rem 0rem 10rem 0rem;
  }
  .register {
    background-color: transparent;
  }
  .register {
    background-color: transparent;
    padding: 0;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
</style>
